.scrollbar {
  background-color: var(--gray-200) !important;
  border-radius: 4px !important;
  padding-inline: 2px !important;
  padding-block: 8px !important;
}

.thumb {
  background-color: var(--gray-400-transparent) !important;
}

.radius {
  border-radius: 1.25rem;
}
