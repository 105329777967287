.uploadAnother {
  height: 100%;
  display: flex;

  .inputFileButtonWrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .uploadedImage {
    img {
      border-radius: 0.5rem;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
