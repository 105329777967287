.label {
  height: 20px;
  width: auto;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #64748b;
  flex-grow: 1;
}

.colorCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 6px;
  display: block;
}

.titleContainer {
  display: flex;
  gap: 6px;
  margin-left: 24px;
  padding-top: 14px;
}

.amount {
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #0f172a;
}

.total {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #64748b;
}

.amountAndTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  margin-left: 1.5rem;
  width: 100%;
  margin-top: -8px;
}

.percent {
  border-radius: 0.75rem;
  border: 1px solid #eff6ff;
  background-color: #eff6ff;
  align-items: center;
  gap: 0.25rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: 2.625rem;
  color: #3b82f6;
  font-weight: 500;
  font-size: 0.875rem;
}
