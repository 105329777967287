.multiStepFormContentFooter {
    display: flex;
    justify-content: flex-end;

    &.withClearButton {
        justify-content: space-between;
    }

    .buttonActions {
        display: flex;

        gap: 1.25rem;
    }
}
