.spinner div {
  width: 1rem;
  height: 1rem;
  border-width: 0.2rem;
}

.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
