.layoutCellContainer {
  display: flex;
  height: 100%;
  align-items: center;
  column-gap: 12px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-300, #cbd5e1);
  }

  .presentation {
    border-radius: 4px;
    border: 0.5px solid var(--gray-200, #e2e8f0);
    flex-shrink: 0;
    max-width: 80px;
    max-height: 60px;
    width: 100%;
    height: 100%;
  }

  .layoutTextContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;

    .layoutName {
      color: var(--gray-900, #0f172a);

      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .layoutDescription {
      color: var(--gray-500, #64748b);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 14px;
      line-height: 20px; /* 142.857% */
    }
  }

  .layoutImageNoPresentationContainer {
    position: relative;
    display: flex;

    .layoutImageNoPresentationOverlay {
      position: absolute;
      inset: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      box-sizing: border-box;
      background-color: var(--gray-400-transparent);

      .layoutImageMapNoImageIcon {
        font-size: 16px;
      }
      .layoutImageMapNoImageText {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        color: #64748b;
      }
    }
  }
}

:global(.disabled-row) {
  .layoutDescription {
    color: var(--gray-400, #718096);
  }
  .layoutName {
    color: var(--gray-500, #64748b);
  }
}

.divTable {
  width: 100%;
}

.layout_details_modal {
  max-height: 483px !important;
  padding: 24px !important;
  border-radius: 16px !important;
  gap: 32px !important;
  max-width: 900px !important;
}

.qrModal {
  max-width: 304px !important;
  max-height: 364px !important;
}

.qrCode {
  cursor: pointer;
  user-select: none;
}

.layout_details_label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0f172a !important;
}

.layout_details_value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #64748b;
}

.layout_details_image {
  max-width: 500px;
  max-height: 375px;
  border: 0.5px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.align_div_between_age_limits {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  margin-bottom: 1rem;
  width: 300px;
  height: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #fff;
}
