.search {
  margin-bottom: 1rem;
  width: 300px;
  height: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #fff;
}
.table {
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
}
