@import "../../../util.scss";

.responsiveRow {
  display: flex;
  gap: 1.5rem;

  @include breakpoint-range($to: tablet) {
    flex-direction: column;
  }
}
