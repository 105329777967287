.labelStyle {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.tableContainer {
  color: var(--gray-500);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.tableContainer input[type='checkbox'] {
  border-color: var(--gray-400) !important;
}
.selectedRow {
  background-color: var(--blue-50) !important;
}

.tableContainer thead th {
  background-color: var(--gray-50);
  font-weight: 500;
}

.tableContainer thead th:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.25rem;
  background-color: var(--gray-300);
  transform: translateY(-50%);
}

.tableContainer thead th:first-child::after {
  display: none;
}

.firstColumn {
  color: var(--gray-900);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dateAndTimePickerWrapper label {
  @extend .labelStyle;
}
