.labelStyle {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.timeInputPlaceholder {
  color: #94a3b8 !important;
}
