.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #0f172a;
}

.header {
  margin-top: 10px;
}

@media (max-width: 576px) {
  .search {
    width: 100%;
    margin-bottom: 12px;
  }

  .header {
  }
}
