@import '../../util.scss';

.modal {
  max-height: 100vh;

  &:global(.ReactModal__Content) {
    background-color: #fff;
    inset: 50% 0 0 50% !important;
    padding: 1.5rem;
    transform: translate(-50%, -50%);
    position: absolute;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: max-content;

    border-radius: 1rem;

    max-width: 520px;
    width: 100%;
  }

  &.large {
    &:global(.ReactModal__Content) {
      max-width: 800px;
    }
  }

  &.custom80 {
    &:global(.ReactModal__Content) {
      max-width: 80vw;
    }
  }

  &.small {
    &:global(.ReactModal__Content) {
      max-width: 320px;
    }
  }
}
