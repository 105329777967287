.buttonInner {
  gap: 0.5rem;
}

.scrollbarThumb {
  background-color: var(--gray-300) !important;
}

.checkboxBody {
  pointer-events: none;
}

.checkboxLabel {
  font-size: 0.875rem;
}
