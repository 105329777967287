.dragAndDropImageInputGroup {
    display: block;
    height: 100%;
    width: 100%;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
