// .inputGroup {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   row-gap: 6px;

//   .label {
//     color: var(--gray-500, #64748b);
//     font-size: 0.875rem;
//     font-weight: 500;
//     line-height: 1.25rem;
//   }

//   .details {
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 20px;
//     background: #0f172a;
//   }
// }
.dateInputGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  .label {
    color: var(--gray-500, #64748b);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}
