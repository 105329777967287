.header {
  display: flex;
  justify-content: space-between;

  .icon {
    cursor: pointer;
    font-size: 24px;
  }

  .titleContainer {
    display: flex;
    column-gap: 8px;
    align-items: center;

    .titleIcon {
      color: var(--gray-400);
      font-size: 24px;
      display: flex;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: #0f172a;
    }

    .closeIcon {
      color: var(--gray-600);
      font-size: 24px;
      display: flex;
    }
  }
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  color: #475569;
}
