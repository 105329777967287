.paginationContainer {
  display: flex;
  margin: 0;
  border-radius: 6px;
  align-items: center;
  justify-content: center;

  .paginationItem {
    position: relative;
    z-index: 1;
    display: flex;
    height: 36px;
    padding: 8px 16px 8px 16px;
    margin-left: -1px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid transparent;
    border-top: 1px solid var(--gray-200, #e2e8f0);
    border-right: 1px solid var(--gray-200, #e2e8f0);
    border-bottom: 1px solid var(--gray-200, #e2e8f0);
    background: var(--White, #fff);

    &:not(.dots) {
      cursor: pointer;
    }
    user-select: none;

    &.Arrow {
      padding: 8px;
      font-size: 20px;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-left-color: var(--gray-200, #e2e8f0);
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right-color: var(--gray-200, #e2e8f0);
    }

    &.active {
      z-index: 2;
      border: 1px solid var(--blue-500, #3b82f6);
      background: var(--blue-50, #eff6ff);
    }

    &:not(.disabled):not(.dots):hover {
      background: var(--blue-100, #dbeafe);
      border: 1px solid var(--blue-600, #2563eb);
      z-index: 3;
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}
