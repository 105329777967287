.contentContainer {
  display: block;
}

.contentContainer.hidden {
  display: none;
}

.mapContainer {
  height: 360px;
}

/* .uploadCoverPhoto {
  height: 266px;
  width: 100%;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
} */

.eventCoverPhotoForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
