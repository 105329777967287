.dateInputGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  .label {
    color: var(--gray-500, #64748b);

    /* Medium/sm */
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem; /* 142.857% */
  }
}
