.tableContainer {
  color: var(--gray-500);
  font-weight: 400;
  height: 2.75rem;
  white-space: nowrap;
  position: relative;
}

.tableContainer input[type='checkbox'] {
  border-color: var(--gray-400) !important;
}

.tableContainer thead th {
  background-color: var(--gray-50);
  font-weight: normal;
}

.tableContainer thead th:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.25rem;
  background-color: var(--gray-300);
  transform: translateY(-50%);
}

.tableContainer thead th:first-child::after,
.tableContainer thead th:nth-child(2)::after,
.tableContainer thead th:nth-last-child(2)::after {
  display: none;
}

.actionMenuItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background-color: transparent;
}

.menuItem {
  display: flex;
  align-items: center;
  min-height: 2.25rem;
  font-weight: 300;
  line-height: 1rem;
  min-width: 11rem;
  border-radius: 0.625rem;
  color: var(--gray-500) !important;
}

.menuItem svg {
  margin-right: 0.5rem;
}

.selectedRow {
  background-color: var(--blue-50) !important;
}

.firstColumn {
  color: var(--gray-900);
  font-weight: 500;
}

.cursorPointer {
  cursor: pointer;
}
