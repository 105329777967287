.ButtonGroupData {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  /*border: 1px solid #e2e8f0;*/

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  /*border-radius: 8px;*/
}

.button {
  gap: 8px;
}
