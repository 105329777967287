.promptModal {
  &:global(.ReactModal__Content) {
    max-width: 20rem;
    gap: 1.5rem;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
    text-align: center;

    .iconPic {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #f1f5f9;

      &.deleteBg {
        background: #ffe4e6;
      }

      &.enableBg {
        background: #d1fae5;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h3,
      p {
        margin: 0;
      }
    }

    .warningMessage {
      color: var(--gray-900, #0f172a);
      text-align: center;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    width: 100%;

    button {
      width: 100%;
    }

    .downloadText {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3b82f6 !important;
    }
  }
}
