.notAvailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1rem 1.25rem 1rem;
  border-radius: 0.5rem;
  border: 1.5px solid #cbd5e1;
}

.notAvailable .message {
  font-size: 0.875rem;
  line-height: 1.4286;
}
