.qrReader {
  width: 300px;
  height: 300px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.inputItemContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;

  .inputItem {
  }
}

.scanInputGroupContainer {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.25rem;
}

.form {
  display: contents;
}

.buttonActionGroup {
  padding: 0;
  width: 100%;

  :global(.lnc-button) {
    flex: 1 1 0;
  }
}
