.filterButtonGroup {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  padding: 0;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
}

.button {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(---gray-500) !important;
}

.button.active {
  background-color: #f1f5f9;
  color: #fff;
}

.filterButtonGroup .button img {
  width: 0.5rem;
  height: 0.5rem;
}
.text1Style {
  color: var(--gray-400);
  font-weight: 400;
  font-size: 0.875rem;
}

.activeButton {
  background-color: red; /* Primer boje za aktivno dugme */
  color: white;
  border: none;
}

.filterButtonGroup.withBorder {
  background-color: #ffffff;
  border: 1px solid var(--gray-200);
  border-radius: 0.75rem 0.75rem 0 0;
  margin-bottom: -1px;
  padding: 1rem;
}
