@import "../../../util.scss";

.multiStep {
    display: flex;
    flex-direction: column;

    padding: 0 10px 0 5px;

    overflow-y: auto;

    @include scroller;
}
