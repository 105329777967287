.container {
  display: flex;
  justify-content: space-between;
  padding: 1.375rem 1rem;
  background: var(--gray-0);
  border: 1px solid var(--gray-200);
  border-radius: 0.75rem;
  font-size: 0.875rem;
  height: 4.063rem;
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.selectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  border-radius: 0.5rem !important;
}

.select {
  width: 4.6rem;
  border-radius: 0.5rem;
}
