.inputSearch {
  display: flex;
  padding: 0.5rem 0.75rem;
  flex: 1;
  gap: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #e2e8f0;
  box-shadow: 0px 1px 2px 0px #0000000d;
}

.inputSearch:focus-within {
  outline-offset: 0.188rem;
  outline: 0.125rem solid #3b82f6;
}

.inputSearch input {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25;
  caret-color: #3b82f6;
  padding: 0;
}

.inputSearch input::placeholder {
  font-size: 0.875rem;
  line-height: 1.4286;
  color: #94a3b8;
  text-align: left;
}

.inputSearch span {
  align-self: center;
  font-size: 0.875rem;
  color: #94a3b8;
}
