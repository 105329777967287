.tableHeader {
  padding: 16px;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 15px 15px 0% 0%;
  position: relative;
  border-bottom: unset;
  display: flex;
}
.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
}
.actionButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.actionButton img {
  margin-right: 10px;
}
.disableIcon {
  filter: invert(30%) sepia(96%) saturate(7491%) hue-rotate(357deg)
    brightness(106%) contrast(118%);
}
.trashIcon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(267deg)
    brightness(107%) contrast(101%);
  margin-right: 10px;
}
.buttonGroup button {
  margin-right: 10px;
}
.importButton {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #344155;
}
.importButton img {
  margin-right: 10px;
}

.addButton.addButton {
  margin-left: auto;
  font-weight: 500;
  color: #3b82f6;
  line-height: 1.4286;
}

.addButton.addButton:hover {
  color: #0062ff;
}

.addButton.addButton:hover path {
  fill: #0062ff;
}

.addButton .addButtonImg {
  margin-right: 0.5rem;
}

.addButton .addButtonImg path {
  fill: #3b82f6;
}
