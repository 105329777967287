.qr-reader {
  width: 300px !important;
  height: 300px !important;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 50% !important;
  height: 50% !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}

.qr-reader .qr-frame {
  width: 100%;
  height: 100%;
}
