.imagePreviewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 30px;
}

.imageInput {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.imageInputLabel {
  display: flex;
  /* margin: 16px 8px 0 auto; */
  overflow: hidden;
}

.imageInputLabelSpan {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  text-align: center;
  color: #3b82f6;
  cursor: pointer;
  display: inline-block;
}

.imageInputLabelSpan:hover {
  background-color: #f2f3f4;
}

.imagePreview {
  max-width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.imageInputWithImage {
  position: absolute;
  inset: 16px 8px auto auto;
  z-index: 1;
}

.dndFileText {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  order: 1;

  & + button {
    all: unset;
    color: var(--blue-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      color: var(--blue-800);
    }

    &:after {
      content: ' or drag and drop';
      color: var(--gray-700);
      font-weight: 400;
    }
  }
}
