.profile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(220, 219, 219);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text {
  margin-left: 15px;
  text-align: left;
  color: #344155;
}

.company {
  color: #94a3b8;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0f172a;
}

.menuItem a:visited {
  color: #0f172a;
}

.monogram {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;

  width: 36px;
  height: 36px;
  background: #e2e8f0;
  border-radius: 4px;
  margin-right: 12px;
  font-size: 20px;
}

.content {
  margin-left: 12px;
}

.role {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #64748b;
}

.dropdownMenu {
  z-index: 21;
}
