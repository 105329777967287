.divTable {
  width: 100%;
}
.modalFooter {
  margin-top: 2rem;
  justify-content: right;
}

.unassigned {
  color: #ca8a04;
}

.search {
  margin-bottom: 1rem;
  width: 300px;
  height: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #fff;
}
