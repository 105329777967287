body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --gray-0: #ffffff;
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;

  --gray-400-transparent: #94a3b850;

  --neutral-0: #ffffff;
  --neutral-50: #fafafa;
  --neutral-100: #f4f4f5;
  --neutral-200: #e4e4e7;
  --neutral-300: #d4d4d8;
  --neutral-400: #a1a1aa;
  --neutral-500: #71717a;
  --neutral-600: #52525b;
  --neutral-700: #3f3f46;
  --neutral-800: #27272a;
  --neutral-900: #18181b;

  --blue-0: #ffffff;
  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;

  --teal-0: #ffffff;
  --teal-50: #ecfeff;
  --teal-100: #cffafe;
  --teal-200: #a5f3fc;
  --teal-300: #67e8f9;
  --teal-400: #22d3ee;
  --teal-500: #3b82f6;
  --teal-600: #0891b2;
  --teal-700: #0e7490;
  --teal-800: #155e75;
  --teal-900: #164e63;

  --red-0: #ffffff;
  --red-50: #fff1f2;
  --red-100: #ffe4e6;
  --red-200: #fecdd3;
  --red-300: #fda4af;
  --red-400: #fb7185;
  --red-500: #f43f5e;
  --red-600: #e11d48;
  --red-700: #be123c;
  --red-800: #9f1239;
  --red-900: #881337;

  --violet-0: #ffffff;
  --violet-50: #f5f3ff;
  --violet-100: #ede9fe;
  --violet-200: #ddd6fe;
  --violet-300: #c4b5fd;
  --violet-400: #a78bfa;
  --violet-500: #8b5cf6;
  --violet-600: #7c3aed;
  --violet-700: #6d28d9;
  --violet-800: #5b21b6;
  --violet-900: #4c1d95;

  --yellow-0: #ffffff;
  --yellow-50: #fefce8;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;

  --green-0: #ffffff;
  --green-50: #ecfdf5;
  --green-100: #d1fae5;
  --green-200: #a7f3d0;
  --green-300: #6ee7b7;
  --green-400: #34d399;
  --green-500: #10b981;
  --green-600: #059669;
  --green-700: #047857;
  --green-800: #065f46;
  --green-900: #064e3b;

  @mixin light {
    --mrt-base-background-color: rgb(50, 50, 50);
  }
}

.capitalize {
  text-transform: capitalize;
}

/* #region Background Colors */
.bg-gray-0 {
  background-color: var(--gray-0) !important;
}
.bg-gray-50 {
  background-color: var(--gray-50) !important;
}
.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--gray-800) !important;
}

.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.bg-neutral-0 {
  background-color: var(--neutral-0) !important;
}

.bg-neutral-50 {
  background-color: var(--neutral-50) !important;
}

.bg-neutral-100 {
  background-color: var(--neutral-100) !important;
}

.bg-neutral-200 {
  background-color: var(--neutral-200) !important;
}

.bg-neutral-300 {
  background-color: var(--neutral-300) !important;
}

.bg-neutral-400 {
  background-color: var(--neutral-400) !important;
}

.bg-neutral-500 {
  background-color: var(--neutral-500) !important;
}

.bg-neutral-600 {
  background-color: var(--neutral-600) !important;
}

.bg-neutral-700 {
  background-color: var(--neutral-700) !important;
}

.bg-neutral-800 {
  background-color: var(--neutral-800) !important;
}

.bg-neutral-900 {
  background-color: var(--neutral-900) !important;
}

.bg-blue-0 {
  background-color: var(--blue-0) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}

.bg-blue-100 {
  background-color: var(--blue-100) !important;
}

.bg-blue-200 {
  background-color: var(--blue-200) !important;
}

.bg-blue-300 {
  background-color: var(--blue-300) !important;
}

.bg-blue-400 {
  background-color: var(--blue-400) !important;
}

.bg-blue-500 {
  background-color: var(--blue-500) !important;
}

.bg-blue-600 {
  background-color: var(--blue-600) !important;
}

.bg-blue-700 {
  background-color: var(--blue-700) !important;
}

.bg-blue-800 {
  background-color: var(--blue-800) !important;
}

.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.bg-teal-0 {
  background-color: var(--teal-0) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}

.bg-teal-100 {
  background-color: var(--teal-100) !important;
}

.bg-teal-200 {
  background-color: var(--teal-200) !important;
}

.bg-teal-300 {
  background-color: var(--teal-300) !important;
}

.bg-teal-400 {
  background-color: var(--teal-400) !important;
}

.bg-teal-500 {
  background-color: var(--teal-500) !important;
}

.bg-teal-600 {
  background-color: var(--teal-600) !important;
}

.bg-teal-700 {
  background-color: var(--teal-700) !important;
}

.bg-teal-800 {
  background-color: var(--teal-800) !important;
}

.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.bg-red-0 {
  background-color: var(--red-0) !important;
}

.bg-red-50 {
  background-color: var(--red-50) !important;
}

.bg-red-100 {
  background-color: var(--red-100) !important;
}

.bg-red-200 {
  background-color: var(--red-200) !important;
}

.bg-red-300 {
  background-color: var(--red-300) !important;
}

.bg-red-400 {
  background-color: var(--red-400) !important;
}

.bg-red-500 {
  background-color: var(--red-500) !important;
}

.bg-red-600 {
  background-color: var(--red-600) !important;
}

.bg-red-700 {
  background-color: var(--red-700) !important;
}

.bg-red-800 {
  background-color: var(--red-800) !important;
}

.bg-red-900 {
  background-color: var(--red-900) !important;
}

.bg-violet-0 {
  background-color: var(--violet-0) !important;
}

.bg-violet-50 {
  background-color: var(--violet-50) !important;
}

.bg-violet-100 {
  background-color: var(--violet-100) !important;
}

.bg-violet-200 {
  background-color: var(--violet-200) !important;
}

.bg-violet-300 {
  background-color: var(--violet-300) !important;
}

.bg-violet-400 {
  background-color: var(--violet-400) !important;
}

.bg-violet-500 {
  background-color: var(--violet-500) !important;
}

.bg-violet-600 {
  background-color: var(--violet-600) !important;
}

.bg-violet-700 {
  background-color: var(--violet-700) !important;
}

.bg-violet-800 {
  background-color: var(--violet-800) !important;
}

.bg-violet-900 {
  background-color: var(--violet-900) !important;
}

.bg-yellow-0 {
  background-color: var(--yellow-0) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}

.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}

.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}

.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}

.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}

.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}

.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}

.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}

.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.bg-green-0 {
  background-color: var(--green-0) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}

.bg-green-100 {
  background-color: var(--green-100) !important;
}

.bg-green-200 {
  background-color: var(--green-200) !important;
}

.bg-green-300 {
  background-color: var(--green-300) !important;
}

.bg-green-400 {
  background-color: var(--green-400) !important;
}

.bg-green-500 {
  background-color: var(--green-500) !important;
}

.bg-green-600 {
  background-color: var(--green-600) !important;
}

.bg-green-700 {
  background-color: var(--green-700) !important;
}

.bg-green-800 {
  background-color: var(--green-800) !important;
}

.bg-green-900 {
  background-color: var(--green-900) !important;
}
/* #endregion */

/* #region Text Colors */
.text-gray-0 {
  color: var(--gray-0) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}

.text-gray-100 {
  color: var(--gray-100) !important;
}

.text-gray-200 {
  color: var(--gray-200) !important;
}

.text-gray-300 {
  color: var(--gray-300) !important;
}

.text-gray-400 {
  color: var(--gray-400) !important;
}

.text-gray-500 {
  color: var(--gray-500) !important;
}

.text-gray-600 {
  color: var(--gray-600) !important;
}

.text-gray-700 {
  color: var(--gray-700) !important;
}

.text-gray-800 {
  color: var(--gray-800) !important;
}

.text-gray-900 {
  color: var(--gray-900) !important;
}

.text-neutral-0 {
  color: var(--neutral-0) !important;
}

.text-neutral-50 {
  color: var(--neutral-50) !important;
}

.text-neutral-100 {
  color: var(--neutral-100) !important;
}

.text-neutral-200 {
  color: var(--neutral-200) !important;
}

.text-neutral-300 {
  color: var(--neutral-300) !important;
}

.text-neutral-400 {
  color: var(--neutral-400) !important;
}

.text-neutral-500 {
  color: var(--neutral-500) !important;
}

.text-neutral-600 {
  color: var(--neutral-600) !important;
}

.text-neutral-700 {
  color: var(--neutral-700) !important;
}

.text-neutral-800 {
  color: var(--neutral-800) !important;
}

.text-neutral-900 {
  color: var(--neutral-900) !important;
}

.text-blue-0 {
  color: var(--blue-0) !important;
}

.text-blue-50 {
  color: var(--blue-50) !important;
}

.text-blue-100 {
  color: var(--blue-100) !important;
}

.text-blue-200 {
  color: var(--blue-200) !important;
}

.text-blue-300 {
  color: var(--blue-300) !important;
}

.text-blue-400 {
  color: var(--blue-400) !important;
}

.text-blue-500 {
  color: var(--blue-500) !important;
}

.text-blue-600 {
  color: var(--blue-600) !important;
}

.text-blue-700 {
  color: var(--blue-700) !important;
}

.text-blue-800 {
  color: var(--blue-800) !important;
}

.text-blue-900 {
  color: var(--blue-900) !important;
}

.text-teal-0 {
  color: var(--teal-0) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}

.text-teal-100 {
  color: var(--teal-100) !important;
}

.text-teal-200 {
  color: var(--teal-200) !important;
}

.text-teal-300 {
  color: var(--teal-300) !important;
}

.text-teal-400 {
  color: var(--teal-400) !important;
}

.text-teal-500 {
  color: var(--teal-500) !important;
}

.text-teal-600 {
  color: var(--teal-600) !important;
}

.text-teal-700 {
  color: var(--teal-700) !important;
}

.text-teal-800 {
  color: var(--teal-800) !important;
}

.text-teal-900 {
  color: var(--teal-900) !important;
}

.text-red-0 {
  color: var(--red-0) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}

.text-red-100 {
  color: var(--red-100) !important;
}

.text-red-200 {
  color: var(--red-200) !important;
}

.text-red-300 {
  color: var(--red-300) !important;
}

.text-red-400 {
  color: var(--red-400) !important;
}

.text-red-500 {
  color: var(--red-500) !important;
}

.text-red-600 {
  color: var(--red-600) !important;
}

.text-red-700 {
  color: var(--red-700) !important;
}

.text-red-800 {
  color: var(--red-800) !important;
}

.text-red-900 {
  color: var(--red-900) !important;
}

.text-violet-0 {
  color: var(--violet-0) !important;
}

.text-violet-50 {
  color: var(--violet-50) !important;
}

.text-violet-100 {
  color: var(--violet-100) !important;
}

.text-violet-200 {
  color: var(--violet-200) !important;
}

.text-violet-300 {
  color: var(--violet-300) !important;
}

.text-violet-400 {
  color: var(--violet-400) !important;
}

.text-violet-500 {
  color: var(--violet-500) !important;
}

.text-violet-600 {
  color: var(--violet-600) !important;
}

.text-violet-700 {
  color: var(--violet-700) !important;
}

.text-violet-800 {
  color: var(--violet-800) !important;
}

.text-violet-900 {
  color: var(--violet-900) !important;
}

.text-yellow-0 {
  color: var(--yellow-0) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}

.text-yellow-100 {
  color: var(--yellow-100) !important;
}

.text-yellow-200 {
  color: var(--yellow-200) !important;
}

.text-yellow-300 {
  color: var(--yellow-300) !important;
}

.text-yellow-400 {
  color: var(--yellow-400) !important;
}

.text-yellow-500 {
  color: var(--yellow-500) !important;
}

.text-yellow-600 {
  color: var(--yellow-600) !important;
}

.text-yellow-700 {
  color: var(--yellow-700) !important;
}

.text-yellow-800 {
  color: var(--yellow-800) !important;
}

.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.text-green-0 {
  color: var(--green-0) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}

.text-green-100 {
  color: var(--green-100) !important;
}

.text-green-200 {
  color: var(--green-200) !important;
}

.text-green-300 {
  color: var(--green-300) !important;
}

.text-green-400 {
  color: var(--green-400) !important;
}

.text-green-500 {
  color: var(--green-500) !important;
}

.text-green-600 {
  color: var(--green-600) !important;
}

.text-green-700 {
  color: var(--green-700) !important;
}

.text-green-800 {
  color: var(--green-800) !important;
}

.text-green-900 {
  color: var(--green-900) !important;
}
/* #endregion */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Overlay {
  background-color: rgba(15, 23, 42, 0.5) !important;
  z-index: 900;
}

.hide {
  display: none !important;
}
.show {
  display: flex !important;
}

.contents {
  display: contents;
}

.image-marker__marker {
  display: flex;
}

/* Stilizacija skrolbara  */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--gray-200);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-400-transparent);
  border-radius: 4px;
  box-shadow: inset 2px 0 0 0 #e2e8f0, inset -2px 0 0 0 #e2e8f0,
    inset 0 7px 0 0 #e2e8f0, inset 0 -7px 0 0 #e2e8f0;
  transition: background 0.3s ease;
}

/* Mantine stilovi */
.mantine-Anchor-root {
  color: var(--gray-500) !important;
}

.mantine-Breadcrumbs-root {
  margin: 0 0 0 0.5rem !important;

  p:last-child {
    color: var(--gray-900) !important;
    cursor: default;
  }
}

.mantine-Breadcrumbs-breadcrumb {
  font-weight: 500;
  cursor: pointer;
}

.mantine-Breadcrumbs-separator {
  height: 1.25rem;
  width: 1.25rem;
  color: var(--gray-400) !important;
}

.mantine-Button-root {
  padding: 0.5rem 0.75rem !important;
  border-radius: 0.5rem;

  &:disabled {
    cursor: default;
    background-color: var(--gray-100) !important;
    color: var(--gray-400) !important;
  }
}

.mantine-Button-inner {
  gap: 0.25rem;
}

.mantine-Button-section {
  margin: 0 !important;
}

.mantine-Button-label {
  font-weight: 500;
}

.mantine-ActionIcon-root {
  border-radius: 8px;

  &:disabled {
    cursor: default;
    background-color: var(--gray-100) !important;
    color: var(--gray-400) !important;
  }
}

.mantine-Tabs-list {
  gap: 1rem !important;

  &::before {
    border: 1px solid var(--gray-200);
  }
}

.mantine-Tabs-tab {
  padding: 0.5rem 0.5rem 1rem 0.5rem !important;
}

.mantine-Tabs-tabSection {
  height: 1.25rem;
  width: 1.25rem;
  color: var(--gray-400);
  margin-right: 0.5rem !important;
}

.mantine-Tabs-tabLabel {
  font-weight: 500;
  color: var(--gray-500);
}

.mantine-Tabs-panel {
  margin-top: 2rem;
}

.mantine-Tabs-tab[data-active] {
  .mantine-Tabs-tabLabel {
    color: var(--blue-500) !important;
  }

  .mantine-Tabs-tabSection {
    color: var(--blue-500) !important;
  }
}

.mantine-Combobox-dropdown {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06),
    0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.mantine-Combobox-option {
  border-radius: 0;
}

.mantine-Menu-dropdown {
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06),
    0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.mantine-Menu-item {
  padding: 0.75rem 1rem;
  border-radius: 0;
}

.mantine-Modal-header {
  padding-bottom: 0;
}

.mantine-Modal-content {
  padding: 1.5rem;
  border-radius: 16px;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mantine-Modal-body {
  padding: 0;
}

.mantine-Select-input {
  border-radius: 8px;
}

.mantine-Select-option[data-checked='true'] {
  color: #3b82f6;
}

.mantine-Input-input {
  border-radius: 8px;
}

.MRT_TableHeadCell-module_root__6y50a[data-column-pinned][data-column-pinned='left'][data-last-left-pinned] {
  background-color: var(--gray-50);
  box-shadow: none;
}

.MRT_TableHeadCell-module_root__6y50a[data-column-pinned][data-column-pinned='right'][data-first-right-pinned] {
  background-color: var(--gray-50);
  box-shadow: none;
}

.MRT_TableBodyRow-module_root__2c3D4
  td[data-column-pinned][data-column-pinned='left'][data-last-left-pinned]::before {
  box-shadow: none;
}

.MRT_TableBodyRow-module_root__2c3D4
  td[data-column-pinned][data-column-pinned='right'][data-first-right-pinned]::before {
  box-shadow: none;
}
