.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-weigh);
  font-size: 0.875rem;
  line-height: 1.4286;
  color: #64748b;

  .actionGroup {
    display: flex;
    gap: 0.75rem;
  }
}
