.tableFooter {
  padding: 16px;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 0% 0% 15px 15px;
  position: relative;
  border-top: unset;
  display: flex;
  justify-content: space-between;
}
.tableFooter p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #64748b;
}
.tableFooter p span {
  font-weight: 500;
}
