.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 32px;

  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  height: 100px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 32px;
  gap: 32px;

  width: 100%;
  height: auto;
}

.headerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;

  width: 68px;
  height: 36px;

  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.headingWithAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 36px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 36px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.breadcrumbs_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
}

@media (max-width: 800px) {
  .tabs.tabs {
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    gap: 0.5rem;
    z-index: 19;
    background-color: #fff;
    padding: 0.5rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  }

  .tabs .tab {
    flex: 1 1 0;
  }
}
.tabs_with_underline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;

  width: 100%;
  height: 44px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  height: 44px;
}

.tab svg {
  font-size: 24px;
}

.cover,
.cover_without_image {
  width: 100%;

  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.cover_without_image {
  background: #ffffff;
  min-height: 100px;
  border: 1px solid #e2e8f0;
}

.content_column1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 16px;
  gap: 32px;

  height: auto;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

@media (max-width: 800px) {
  .content_column1 {
    padding-right: 0px;
  }
}

.content_column2 {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.column2_tag {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  height: auto;

  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  border-radius: 12px;
  background: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.column2_tag_primary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 32px;

  width: auto;
  height: 76px;

  /* White */

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.column2_tag_secondary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 24px;
  gap: 8px;

  width: 100%;
  height: auto;

  /* White */
}

.column2_tag_secondary_title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Gray/900 */

  color: #0f172a;
  justify-content: space-between;
  align-self: stretch;
}

.column2_tag_primary_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: auto;
  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.column2_tag_primary_header_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: auto;
  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.column2_tag_primary_header_number {
  width: auto;
  height: 28px;

  /* Medium/lg */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  text-align: right;

  /* Gray/500 */

  color: #64748b;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 1;
}

.column1_overview {
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.column1_overview_header {
  width: auto;
  height: 28px;

  /* SemiBold/lg */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Gray/900 */

  color: #0f172a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.column1_overview_content_description {
  height: auto;

  /* Regular/sm */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Gray/900 */

  color: #0f172a;

  /* Inside auto layout */

  text-align: left;
  margin: 10px 0px;
}

.column1_registration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  width: 100%;
  height: auto;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.column1_contact_info {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  width: 100%;
  height: auto;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

.iconActive path {
  fill: rgba(29, 78, 216, 100%);
}

a {
  text-decoration: none;
}

.breadcrumb_item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;

  width: auto;
  height: 36px;

  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #64748b;
}

.breadcrumb_item2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 2px;
  gap: 8px;

  width: 24px;
  height: 36px;

  border-radius: 8px;
}

.breadcrumb_item3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;

  width: 195px;
  height: 36px;

  border-radius: 8px;
  width: auto;

  /* Medium/base */

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/900 */

  color: #0f172a;
}
.column1_overview_content_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: auto;
  height: auto;
}

.column1_overview_content_data_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: auto;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/900 */

  color: #0f172a;
}

.cover_actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  padding: 0px;
  gap: 20px;

  position: absolute;
  padding-left: 20px;
  padding-right: 20px;

  bottom: 0px;
  padding-bottom: 20px;
  width: 100%;
}

.cover_info {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: auto;
  color: #ffffff;
}

.cover_image {
  width: 100%;
  height: auto;
}
.edit_delete {
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.event_progress_actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 12px;
  align-self: stretch;
}

.event_progress_actions_back_next {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.event_progress_body {
  padding: 0px 24px;
}
.event_progress_body_top {
  padding-top: 24px;
}

.closed {
  display: none;
}

.opened {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.dropdownItem div {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0f172a;
}
.dropdownItem img {
  margin-right: 10px;
}
.dropdownItem img.delete {
  filter: invert(12%) sepia(98%) saturate(5216%) hue-rotate(359deg)
    brightness(102%) contrast(116%);
}

.dropdownItem .delete {
  color: red;
}
.separator {
  height: 2px;
  background: #e2e8f0;
}
.participant_legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  gap: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: start;
}

.participant_legend_item {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.doughnut {
  width: 200px;
  height: 200px;
  padding: 20px;
}

.actionButton {
  display: flex;
  flex-direction: row;
  gap: 7px;
  color: #344155 !important;
}
.text_align {
  text-align: start;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #0f172a;
}

.event_duration_status {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.event_duration {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* White */

  color: #ffffff;

  /* Shadow/sm */

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.event_duration_without_image {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* White */

  color: rgb(71, 85, 105);

  /* Shadow/sm */

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.event_name {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  /* White */

  color: #ffffff;

  /* Shadow/rg */

  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.event_name_without_image {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  /* White */

  color: rgb(71, 85, 105);

  /* Shadow/rg */

  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.event_timing_location_details {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
}

.event_timing_location_details_buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.timing_location_details_about {
  padding: 24px;

  width: 100%;
  height: auto;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 40px;
}

.timing_location_details_organisation_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  width: 100%;
}

.timing_location_details_devices {
  margin-top: 40px;
  width: 100%;
}

.timing_location_details_organisation_info_header {
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 100%;
  height: 100px;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  text-align: left;
}

.timing_location_details_organisation_info_details_contact_person {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}
.timing_location_details_organisation_info_details {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 60%;
  height: auto;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  text-align: left;
}
.timing_location_details_organisation_info_contact_person {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  width: 40%;
  height: auto;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  text-align: left;
}

.timing_location_details_about_map {
  box-sizing: border-box;

  width: 100%;
  height: 354px;

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.timing_location_details_about_content {
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: auto;
  text-align: left;
}

.timing_location_details_about_content_header_title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #0f172a;
  padding-bottom: 20px;
}

.timing_location_details_about_content_title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #0f172a;
}

.timing_location_details_about_content_item {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: #64748b;
}

.timing_location_details_about_content_item_header {
  font-weight: 500;
  line-height: 20px;
  color: #0f172a;
}

.upload_image_map {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  height: 36px;

  /* Gray/200 */

  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.upload_image_map_item {
  width: 50%;
  text-align: center;
}

.upload_image_map_item_image {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 50%;
  height: 36px;

  /* Gray/100 */

  background: #f1f5f9;
  /* Gray/200 */

  border-right: 1px solid #e2e8f0;
}

.upload_image_map_item_map {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 50%;
  height: 36px;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border-right: 1px solid #e2e8f0;
}
.layout_details_image_map {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 16px; */
  max-width: 900px;
  height: 483px;
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  background: white;
  /* Gray/200 */

  /* border: 0.5px solid #e2e8f0;
  border-radius: 8px; */
}

.layout_details_image_map_no_image {
  font-size: 64px;
}

.layout_details_image_map_no_image_text {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #64748b;
}

.align_div_between_age_limits_layout_form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_width {
  width: 100%;
}
.event_about_wrapper {
  width: 100%;
}

.qrModal {
  max-width: 304px !important;
  max-height: 364px !important;
}

.hideImage {
  display: none;
}

.trash_color {
  color: #f43f5e !important;
}

/* @media (max-width: 500px) and (max-width: 1250) {
  .cover_actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px;
    gap: 20px;

    position: absolute;
    height: 56px;
    padding-left: 20px;
    padding-right: 20px;

    bottom: 150px;
    width: 100%;
  }
} */

@media (max-width: 700px) {
  .event_name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    /* identical to box height, or 120% */

    letter-spacing: -0.02em;

    /* White */

    color: #ffffff;

    /* Shadow/rg */

    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  }
}

.add_event_devices {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.timing_location_details_about_content_item_social_media {
  display: flex;
  gap: 10px;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: #64748b;
}
