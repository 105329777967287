.header {
  background-color: rgba(37, 99, 235, 20%);
  color: rgba(29, 78, 216, 100%);
}

.card {
  margin-bottom: 10px;
}

.locationText {
  font-weight: 600;
}
