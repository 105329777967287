.wrapper {
  min-height: 100vh;
}
.wrapper .content {
  background: #f8fafc;
  min-height: 100vh;
  padding: 32px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: #ffffff;
  padding: 40px 40px 24px 40px;
  gap: 15px;
  width: 30%;
  border-radius: 1rem;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  display: flex;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}
.backgroundImage {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/Gallery.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.loginButton {
  /* Auto layout */
  font-size: 16px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  border-color: #3b82f6;
  height: 48px;

  /* Blue/500 */

  background: #3b82f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  /* Shadow/sm */

  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.dontAccount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 32px;
  gap: 10px;

  height: 64px;

  /* Gray/50 */

  background: #f8fafc;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

a {
  text-decoration: none;
}

.forgottenPass {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Blue/500 */

  color: #3b82f6;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}

.naslov1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.01em;

  /* Gray/900 */

  color: #0f172a;
}

.naslov2 {
  /* Regular/sm */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Gray/900 */

  color: #0f172a;
  padding-bottom: 15px;
}

.email {
  box-sizing: border-box;

  /* Auto layout */

  padding: 14px;
  gap: 8px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #cbd5e1;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.pass {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 14px;
  gap: 8px;

  height: 52px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #cbd5e1;
  /* Shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.logo {
  width: 160px;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
}

.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.errorLabel {
  color: var(--red-500, #f43f5e);
  text-align: center;
  /* Regular/sm */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
