.wrapper {
  min-height: 100vh;
}
.wrapper .content {
  background: #f8fafc;
  min-height: 100vh;
  padding: 32px;
}

.menu {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 800px) {
  .wrapper .content {
    padding-bottom: 6rem;
  }
}

@media (min-width: 1024px) {
  .menu {
    display: none;
  }
}
