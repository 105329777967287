.filter-button-group {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  padding: 0;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  width: 100%;
}

.button {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(---gray-500) !important;
}

.filter-button-group .button img {
  width: 0.5rem;
  height: 0.5rem;
}
.text1-style {
  color: var(--gray-400);
  font-weight: 400;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
