.separatorLine {
  height: 1px;
  margin: 20px 0;
  border-top: 1px solid var(--gray-200);
}

.filterButtonGroupContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.extraButtonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.extraButtonContainer button {
  white-space: nowrap;
}

.headerAndFilterGroup {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.25rem 1.5rem;
  width: 100%;
  background: var(--gray-0);
  border: 1px solid var(--gray-200);
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 0.75rem 0.75rem 0 0;
}

.filterButtonGroupContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
