.table {
  width: 100%;
}

.search {
  margin-bottom: 1rem;
  width: 300px;
  height: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #fff;
}
