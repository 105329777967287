.progressStepsTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: -0.01em;

  color: var(--gray-900);
  padding-bottom: 10px;
}

.progressSteps {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 6px;

  align-self: stretch;
}
