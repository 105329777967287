.CreateOrUpdateParticipantForm {
  display: flex;
  flex-direction: column;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .content {
    grid-template-columns: 1fr;
  }
}
