.eventCoverPhotoForm {
    .dndFileText {
        display: flex;
        flex-direction: column;
        justify-content: center;

        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        order: 1;

        & + button {
            all: unset;
            color: var(--blue-500);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            &:hover {
                color: var(--blue-800);
            }

            &:after {
                content: ' or drag and drop';
                color: var(--gray-700);
                font-weight: 400;
            }
        }
    }
}
