.buttonActionGroup {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
}

.start {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}
