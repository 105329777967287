.stats {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: auto;
  height: 80px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-bottom: 20px;
}

.divider {
  width: 1px;
  height: 80px;
  background: #e2e8f0;
}

.stat {
  width: 100%;
  border-right: 1px solid #e2e8f0;
  height: 80px;
}

.statLast {
  width: 100%;
  height: 80px;
}
