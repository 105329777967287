.user {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
  text-align: left;
}

.column2_tag {
  margin: 1rem;
  border: 1px solid #e2e8f0;
  /* Shadow/sm */

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  border-radius: 12px;
  background: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.column2_tag_primary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 32px;

  width: auto;

  /* White */

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.column2_tag_secondary {
  padding: 12px 24px 24px;
  gap: 8px;

  width: 100%;
  height: auto;
  background: #f8fafc;
  border-radius: 12px;
  margin-top: 1rem;
}

.column2_tag_secondary_title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Gray/900 */

  color: #0f172a;
  justify-content: space-between;
  align-self: stretch;
}

.column2_tag_primary_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: auto;
  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.column2_tag_primary_header_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: auto;
  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.column2_tag_primary_header_number {
  font-family: 'Inter';
  font-style: normal;
  font-size: 32px;
  line-height: 14px;
  /* identical to box height, or 156% */

  text-align: right;

  color: #0f172a;

  /* Inside auto layout */
}

.right {
  text-align: right;
}

.total {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0f172a;
  text-align: left;
}

.number {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.01em;

  /* Gray/900 */

  color: #0f172a;
  text-align: left;
}

.paddingLeft {
  padding-left: 24px;
}

.paddingRight {
  padding-right: 24px;
}

.paddingX {
  padding-right: 24px;
  padding-left: 24px;
}
.colorSiva {
  color: #94a3b8;
}

.colorPlava {
  color: #3b82f6;
}

.marginBottom {
  margin-bottom: 24px;
}
.textPart {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #64748b;
  text-align: left;
}

.textPart img {
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
}

.numberPart {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0f172a;
  text-align: right;
}

.okvir {
  margin: 0px 24px 0px 24px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: #e2e8f0 1px solid;
}
.paddingAndMargin {
  margin: 0px 24px 0px 24px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.eventPlus {
  margin-top: 12px;

  padding: 2px 6px 2px 2px;

  background: #ecfdf5;
  border-radius: 24px;

  width: 86px;
  height: 24px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Green/600 */

  color: #059669;
}

.eventPlus img {
  margin-right: 0.5rem;
  width: 14px;
  height: 14px;
}

.eventMinus {
  margin-top: 12px;
  padding: 2px 6px 2px 2px;

  background: #fff1f2;
  border-radius: 24px;

  width: 86px;
  height: 24px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Green/600 */

  color: #e11d48;
}

.eventMinus img {
  margin-right: 0.5rem;
  width: 14px;
  height: 14px;
}

.minHeight {
  min-height: 400px;
}

.notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px;
  margin: 0px 12px 12px 12px;
  gap: 12px;
  height: 52px;
  /* Green/50 */
  background: #ecfdf5;
  border-radius: 8px;
}

.notificationText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Green/600 */
  color: #059669;
}

.notificationIcon {
  margin: 0.5rem;
}
