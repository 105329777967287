.tableContainer {
  display: flex;
  flex-direction: column;
}
.table {
  padding: 0 !important;
  margin: 0 !important;
}
.table:not(.noBorder) {
  border: 1px solid #cbd5e0;
}
.table table {
  border-radius: unset;
  padding-top: 0px;
}
