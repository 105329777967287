.table {
  width: 100%;
}

.table :global(.mrt-table) {
  table-layout: fixed;
}

.table :global(.mrt-table-head-cell-resize-handle) {
  width: 1px;
}

.table :global(.mrt-table-head-cell-resize-handle):after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4px;
  cursor: inherit;
}

.table .expandingRow {
  width: unset !important;
}

.tableHeader :global(.mrt-table-head-cell-content) {
  justify-content: space-between;
}

.centeredTableHeader :global(.mrt-table-head-cell-content) {
  justify-content: center;
}
