.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-right: 1px solid #e2e8f0;
  align-items: flex-start;
  padding: 32px 16px 24px;
  gap: 20px;
  height: 100%;
  width: inherit;
  position: fixed;
  z-index: 20;
}

@media (max-width: 1024px) {
  .sidebar.hidden {
    display: none;
  }
}
.menu {
  margin-top: 30px;
  width: 100%;
}
.menuItem:hover {
  background: #eff6ff;
  border-radius: 8px;
}
.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  text-align: left;
  padding: 10px 12px;
  gap: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0f172a;
}

.active {
  background: #eff6ff;
  color: #3b82f6;
}

.closeMenu {
  display: none;
}

@media (max-width: 1024px) {
  .sidebar {
    width: unset;
    z-index: 20;
  }
}

@media (max-width: 1024px) {
  .closeMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: -20px;
  }
}
