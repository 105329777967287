.inputFileButton {
    cursor: pointer;

    padding: 0.5rem 1.25rem;

    border-radius: 0.5rem;

    border: 1px solid var(--gray-200, #e2e8f0);
    background: #fff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    color: var(--blue-500);

    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
        background: var(--gray-200);
    }

    input {
        display: none;
    }
}
