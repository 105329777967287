.defaultVariantScrollbar {
  background-color: var(--gray-200) !important;
  border-radius: 4px !important;
  padding-inline: 2px !important;
  padding-block: 8px !important;
}

.defaultVariantThumb {
  background-color: var(--gray-400-transparent) !important;
}

.thumbVariantThumb {
  background-color: var(--gray-300) !important;
}
