.uploadCoverPhoto {
    height: 266px;
    width: 100%;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}
