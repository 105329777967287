.button {
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.button:hover {
  cursor: pointer;
  background: #e2e8f0 !important;
  border-radius: 8px;
}
.button:active {
  background: #e2e8f0;
  border-radius: 8px;
}

.dropdownItem div {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0f172a;
}
.dropdownItem img {
  margin-right: 10px;
}
.dropdownItem img.delete {
  filter: invert(12%) sepia(98%) saturate(5216%) hue-rotate(359deg)
    brightness(102%) contrast(116%);
}

.dropdownItem .delete {
  color: red;
}
.separator {
  height: 2px;
  background: #e2e8f0;
}
