.inputError {
  position: absolute;
  bottom: -1.2rem;
  color: red;
  font-size: 0.75rem;
  line-height: 1.25;

  &.largeMessage {
    bottom: -2rem;
  }
}
