.tableContainer {
  display: flex;
  flex-direction: column;
  table-layout: fixed;

  border-radius: 12px;
  border: 1px solid var(--gray-200, #e2e8f0);
  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  .dropdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -8px;

    .actionsDropdown {
      cursor: pointer;
      color: var(--gray-600, #475569);
      display: flex;
      padding: 8px;
      font-size: 24px;
      border-radius: 8px;
      background: var(--White, #fff);
      border: none;

      &:hover,
      &:active,
      &:focus {
        background: var(--gray-200, #e2e8f0);
      }
    }
  }

  .loaderContainer {
    display: flex;
    flex-direction: column;
    position: relative;

    .loader {
      position: absolute;
      z-index: 1;
      inset: 0;
      background: #ffffff8a;
    }
  }

  .tableHeader {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    background: var(--White, #fff);

    .tableActions {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 12px;
    }
  }

  table {
    border-spacing: 0;
    border-top: 1px solid var(--gray-200, #e2e8f0);
    border-left: 1px solid var(--gray-200, #e2e8f0);

    thead {
      tr {
        th {
          text-align: left;
          position: relative;

          background: var(--gray-50, #f8fafc);

          color: var(--gray-500, #64748b);

          font-size: 14px;

          font-weight: 500;
          line-height: 20px;

          border-bottom: 1px solid var(--gray-200, #e2e8f0);

          user-select: none;
          padding: 0;
          border: none;

          &:last-child .headerDivider:not(.resizer) {
            display: none;
          }

          &:not(.statusIndicatorHeader) {
            padding: 12px 16px;

            .headerDivider {
              width: 1px;
              height: 20px;

              touch-action: none;

              position: absolute;
              right: 0px;
              top: calc(50% - 10px);

              background: var(--gray-300, #cbd5e1);
              &.resizer {
                cursor: col-resize;

                &:hover {
                  background: var(--gray-400, #94a3b8);
                }
              }

              &::after {
                content: '';
                position: absolute;
                width: 7px;
                height: 100%;
                right: -3px;
                top: 0;
                background: transparent;
              }
            }
          }

          .sortIcon {
            margin-left: 2px;
          }
        }
      }
    }
    tbody {
      :global(.tanstack-table-cell) {
        text-align: left;
        padding: 14px 16px;

        background: var(--White, #fff);

        border-right: 1px solid var(--gray-200, #e2e8f0);
        border-bottom: 1px solid var(--gray-200, #e2e8f0);

        &.actionsCell {
          padding: 0;
        }

        &.selectButtonsCell {
          padding: 0;
        }

        &.statusIndicatorCell {
          padding: 0;
          border-right: none;
          position: relative;

          .statusIndicator {
            position: absolute;
            width: 4px;
            height: calc(100% - 16px);
            top: 50%;
            right: 0%;
            border-radius: 4px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .emptyTable {
    table {
      min-height: 300px;
    }
  }

  .tableFooter {
    display: flex;
    padding: 14px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background: var(--White, #fff);

    .selectedPageCount {
      color: var(--gray-500, #64748b);

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      strong {
        font-weight: 500;
      }
    }
  }

  :global(.indeterminate-checkbox) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -6px -10px;
    margin-left: 0;
  }
}
