.header {
  background: var(--gray-0);
  border: 1px solid var(--gray-200);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
}

.noBorder {
  border: none;
  box-shadow: none;
}
