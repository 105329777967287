@use 'sass:map';
@use 'sass:string';

$break-points: (
  mobile: 480px,
  tablet: 720px,
  desktopS: 960px,
  desktop: 1240px,
  desktopHD: 1920px,
);

/** Helper to build @media query. Use named arguments only! */
@mixin breakpoint-range($mode: screen, $from: false, $to: false, $extra: ()) {
  /** Checking arguments consistency */
  @each $key in ($from, $to) {
    @if $key and not map.has-key($break-points, $key) {
      @error "Available values for ($from, $to) args are: #{map.keys($break-points)}";
    }
  }

  /** Accumulator */
  $conditions: $mode;

  /** Combining breakpoints dependencies */
  @each $key, $value in (min-width: $from, max-width: $to) {
    @if $value {
      $modifier: if($key == 'max-width', -1px, 0px);
      $condition: ' and (#{$key}: #{map.get($break-points, $value) - $modifier})';
      $conditions: string.insert($conditions, $condition, -1);
    }
  }

  /** Combining rest dependencies */
  @each $key, $value in $extra {
    $condition: ' and (#{$key}: #{$value})';
  }

  /** Building final media query */
  @media #{$conditions} {
    @content;
  }
}

@mixin scroller {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8b97b3;
    opacity: 0.5;
    border-radius: 5px;
  }
}
