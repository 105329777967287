.tableSmallContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.tableSmallContainer .tableSmall {
  display: block;
  empty-cells: show;
  padding: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
}

.tableSmallContainer .tableSmall thead {
  display: block;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
}

.tableSmallContainer .tableSmall tbody {
  display: block;
  overflow-y: auto;
  scrollbar-gutter: stable;
  max-height: 315.5px;
}

@media screen and (min-width: 1920px) {
  .tableSmallContainer .tableSmall tbody {
    max-height: 405px;
  }
}

.tableSmallContainer .tableSmall tbody::-webkit-scrollbar {
  width: 6px;
}

.tableSmallContainer .tableSmall tbody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.tableSmallContainer .tableSmall tbody::-webkit-scrollbar-thumb {
  background-color: #8b97b3;
  opacity: 0.5;
  border-radius: 5px;
}

.tableSmallContainer .tableSmall tr {
  display: flex;
}

.tableSmallContainer .tableSmall tr:last-child {
  border-radius: 0 0 0 0.5rem;
}

.tableSmallContainer .tableSmall td,
.tableSmallContainer .tableSmall th {
  padding: 0.75rem 1rem;
  text-align: left;
  line-height: 1.4286;
}

.tableSmallContainer .tableSmall th {
  background-color: #f8fafc;
  border-radius: 0.5rem;
}

.tableSmallContainer .tableSmall th span {
  color: #64748b;
  font-weight: 500;
}

.tableSmallContainer .tableSmall th:nth-child(n + 3) {
  position: relative;
}

.tableSmallContainer .tableSmall th:nth-child(n + 3)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 1.25rem;
  background-color: #cbd5e1;
}

.tableSmallContainer .tableSmall.smallFirstCol td:first-child,
.tableSmallContainer .tableSmall.smallFirstCol th:first-child {
  display: flex;
  padding: 0.375rem 0.5rem;
  flex: 0 0 3rem;
}

.tableSmallContainer .tableSmall.smallFirstCol td:first-child label,
.tableSmallContainer .tableSmall.smallFirstCol th:first-child label {
  justify-content: center;
  align-items: center;
  min-height: initial;
  max-height: initial;
}

.tableSmallContainer
  .tableSmall.smallFirstCol
  th:first-child
  label
  :global(.checkmark) {
  background-color: #fff;
}

.tableSmallContainer
  .tableSmall.smallFirstCol
  th:first-child
  label
  input:checked
  ~ :global(.checkmark) {
  background-color: #0891b2;
  border: 1px solid #0891b2;
}
